@font-face {
  font-family: "Causten Round";
  src: url(theme/fonts/Causten-Round-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "Causten Round";
  src: url(theme/fonts/Causten-Round-Extra-Bold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "Causten Round";
  src: url(theme/fonts/Causten-Round-Semi-Bold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Causten Round";
  src: url(theme/fonts/Causten-Round-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Causten Round";
  src: url(theme/fonts/Causten-Round-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(theme/fonts/sofiaPro/Sofia-Pro-Black.otf);
  font-weight: 900;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(theme/fonts/sofiaPro/Sofia-Pro-Black.otf);
  font-weight: 800;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(theme/fonts/sofiaPro/Sofia-Pro-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(theme/fonts/sofiaPro/Sofia-Pro-Semi-Bold.otf);
  font-weight: 600;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(theme/fonts/sofiaPro/Sofia-Pro-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: "Sofia Pro";
  src: url(theme/fonts/sofiaPro/Sofia-Pro-Regular.otf);
  font-weight: normal;
}
@font-face {
  font-family: "DIN";
  src: url(theme/fonts/din/DIN2014-Bold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "DIN";
  src: url(theme/fonts/din/DIN2014-Demi.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "DIN";
  src: url(theme/fonts/din/DIN2014-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "DIN";
  src: url(theme/fonts/din/DIN2014-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(theme/fonts/interphasesPro/TT-Interphases-Pro-DemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(theme/fonts/interphasesPro/TT-Interphases-Pro-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Interphases Pro";
  src: url(theme/fonts/interphasesPro/TT-Interphases-Pro-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Calibri";
  src: url(theme/fonts/calibri/Calibri-Regular.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "Calibri";
  src: url(theme/fonts/calibri/Calibri-Regular.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "Calibri";
  src: url(theme/fonts/calibri/Calibri-Regular.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Calibri";
  src: url(theme/fonts/calibri/Calibri-Regular.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Calibri";
  src: url(theme/fonts/calibri/Calibri-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Calibri";
  src: url(theme/fonts/calibri/Calibri-Regular.ttf);
  font-weight: normal;
}
html,
body {
  margin: 0;
}

.MuiPickerStaticWrapper-root {
  box-shadow: -2px 0 20px rgba(0, 0, 0, 0.20) !important;
  width: 350px;
}

.addInstitutionStepWizard {
  height: 100%;
}
.addInstitutionStepWizard > div {
  height: 100%;
}
.addInstitutionStepWizard > div > div {
  height: 100%;
}

@media only screen and  (max-width: 600px) {
  html,
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  #root {
    height: 100%;
    width: 100%;
  }
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
}
