.MuiToggleButtonGroup-root {
  padding: 0.25em;
  background-color: rgba(118, 118, 128, 0.12);
  border-radius: 0.5em !important;
}
.MuiToggleButtonGroup-root .Mui-selected {
  background-color: white !important;
}
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  width: 12em;
  border: 0 !important;
  text-transform: none !important;
  font-weight: bold !important;
  padding: 0.25em !important;
  border-radius: 0.5em !important;
}
